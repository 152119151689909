import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/felix/OneDrive/Documents/Bertram.Solutions/Website.TTcom/GatsbyJS-2022/src/components/mdx/component.js";
import { PortfolioPage, Summary, Review, Aside, Notes } from "../../../components/mdx/portfolio.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PortfolioPage;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1>{props.pageContext.frontmatter.author} {props.pageContext.frontmatter.title}</h1>
    <Summary mdxType="Summary">
      <Aside mdxType="Aside">
        <p><strong parentName="p">{`Similar Portfolios`}</strong></p>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/portfolios/tt-all-stars-monthly/"
            }}>{`All-Stars Monthly`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/portfolios/keller-defensive-asset-allocation/"
            }}>{`Defensive Asset Allocation`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/portfolios/keller-classical-asset-allocation/"
            }}>{`Classical Asset Allocation`}</a></li>
        </ul>
      </Aside>
      <ul>
        <li parentName="ul">{`Objective: balanced growth`}</li>
        <li parentName="ul">{`Type: trend-following strategy w/ macro-economic indicator`}</li>
        <li parentName="ul">{`Invests in: ETFs tracking equities, treasuries, and gold`}</li>
        <li parentName="ul">{`Rebalancing schedule: monthly`}</li>
        <li parentName="ul">{`Taxation: 20% short-term capital gains`}</li>
        <li parentName="ul">{`Minimum account size: $4,000`}</li>
      </ul>
      <p>{`Wouter J. Keller is a researcher at the VU University Amsterdam and the CEO of Flex Capital, an asset management firm based in the Netherlands. Keller published the `}<em parentName="p">{`Lethargic Asset Allocation`}</em>{` (LAA) strategy in a paper released in December 2019.`}</p>
      <p>{`The central concept of the `}<em parentName="p">{`Lethargic Asset Allocation`}</em>{` is to combine a macro-economic indicator with trend-following to determine when to switch between risk-on and risk-off portfolio allocations.`}</p>
      <p><em parentName="p">{`LAA`}</em>{` rebalances once per month. Combined with the simplicity of rebalancing, the `}<em parentName="p">{`Lethargic Asset Allocation`}</em>{` has meager maintenance requirements.`}</p>
    </Summary>
    <Review mdxType="Review">
      <h2>{`Strategy Rules`}</h2>
      <Aside mdxType="Aside">
        <p><a parentName="p" {...{
            "href": "https://papers.ssrn.com/sol3/papers.cfm?abstract_id=3498092"
          }}><span parentName="a" {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "591px"
              }
            }}>{`
      `}<span parentName="span" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "141.66666666666666%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAcCAYAAABh2p9gAAAACXBIWXMAAA7DAAAOwwHHb6hkAAADHklEQVRIx6VWiW6jMBTs//9bu2obbgwYGiAH5GzudFbzbNK0sNJut5Jl59ke7Hkzz30AgI+PD/zkb2jfw/3E996MPxdfr1dcLhdpQ2sFsAtUZYnA9xGFIcIghO/78DwXQRBI8zwPRZ6jLEuMx2McDocb4D34DfBw2GO/38vX709yvV5wvpj2N9e/AZbjMeI4xnK5RNu2WCwW0ndjxtnP5w2apkHbNpjP51gtl8Mn5JXjOIJSSoDDMEQURnLdKIqFCtIg8cg0X+YibDabPuCkrgUsSRKoJEGWZTfwRCmoOEaapjLPmKxVCmEUYbVa9QHns5ks5Eki2ZxAqUROEEeRnJIgBGWs+3ieF9hut33Auqokk7xKGAT2apH0BtCAhqGZ54dJUZKkwm8PcNG2KIocVVVhMpmgrivUdY2yrEQqjFd2TNkwidPpVJRxOp2+AxrQ9/etLO5I/olrvnBI3rTWyNIUeZ7L7yzTQn5iE9U10sA5pWK0i8Uwh67jwHU9+J4H13XhOiOMRiO4jgtnZMbk2axzrZs8bDYDSVkuFnh7exOuyF1lueK4ricm1s3Zfjwuhe/z+dwHbJq5aK3TGOWRZSniWAkFok8b17yyXcvr73b7AadUJRzHEVmQs8BKg31s5UNOIyshrmHP0zLTPcDppJZNFHNmT0DCxXYWkB9QEk+QJonMEfC+8twBToRkXqvzbGC9SiCS39kusKLnOqrheDwNWG8+k2uIX5VCXhTItUaucxE8wbTOTDzPpZVVhdlsht1uN3xCz3VNMUgUAp/2+6w+XQUKOzsyxg9rLSWuB8igkYmxV1mOjd0okaqSOSbOWLC0v6s/c3i5XnA8HsWXbBz/yyPVs95sNrV2ym6Vhrwxm2I7WjLLDHdliaIopDjs7iTzBZCKpw79wNiJDxQ5NWMPo5GD19dX4Y2ABOYeanAwy+Tn+flZQAXYgjrWt2wEZHt5ecHT4yN+PT3B8/3hLLdNI5VGZ1oyxyvR2zyJ1pSJlmeUFYZSYZVer9dYrze3x1sA/+c/h1tChji8f7D5HrOCdP33RhWw7/bcP6O/AUHQQFxLR2B3AAAAAElFTkSuQmCC')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="span" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Wouter J. Keller: Growth-Trend Timing and 60-40 Variations: Lethargic Asset Allocation",
                "title": "Wouter J. Keller: Growth-Trend Timing and 60-40 Variations: Lethargic Asset Allocation",
                "src": "/static/bfba5b1be844c48ab24a9b0c329e7570/e4c9a/keller-lethargic-asset-allocation.png",
                "srcSet": ["/static/bfba5b1be844c48ab24a9b0c329e7570/5a46d/keller-lethargic-asset-allocation.png 300w", "/static/bfba5b1be844c48ab24a9b0c329e7570/e4c9a/keller-lethargic-asset-allocation.png 591w"],
                "sizes": "(max-width: 591px) 100vw, 591px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
    `}</span></a></p>
        <p><strong parentName="p">{`Source`}</strong></p>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://papers.ssrn.com/sol3/papers.cfm?abstract_id=3498092"
            }}>{`Growth-Trend Timing and 60-40 Variations`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://papers.ssrn.com/sol3/cf_dev/AbsByAuth.cfm?per_id=1935527"
            }}>{`Wouter J. Keller`}</a></li>
        </ul>
        <p><strong parentName="p">{`Learn More`}</strong></p>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://github.com/fbertram/TuringTrader/blob/develop/BooksAndPubs/Keller_LAA.cs"
            }}>{`Source code`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.bogleheads.org/blog/2014/09/11/harry-brownes-permanent-portfolio/"
            }}>{`Browne's Permanent Portfolio`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "http://www.philosophicaleconomics.com/2016/01/gtt/"
            }}>{`Growth-Trend Timing`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "http://www.philosophicaleconomics.com/2016/02/uetrend/"
            }}>{`Growth-Trend Timing w/ Unemployment Rate`}</a></li>
        </ul>
      </Aside>
      <p>{`The operation of `}<em parentName="p">{`Lethargic Asset Allocation`}</em>{` can be summarized as follows:`}</p>
      <ul>
        <li parentName="ul">{`start with a risk-off asset allocation similar to Harry Browne's `}<em parentName="li">{`Permanent Portfolio`}</em></li>
        <li parentName="ul">{`determine economic cycle based on unemployment numbers`}</li>
        <li parentName="ul">{`determine market regime based on trend following`}</li>
        <li parentName="ul">{`if unemployment numbers are falling `}<em parentName="li">{`or`}</em>{` markets are rising, switch to a risk-on asset allocation`}</li>
      </ul>
      <p>{`The strategy rules go back to Philosophical Economics' work on combining a macro-economic indicator with trend following. They named their concept `}<em parentName="p">{`Growth-Trend Timing`}</em>{` and used it to switch between just two assets: stocks and cash. Philosophical Economics explains the full rationale behind their strategy in a detailed blog post published in 2016. We like their concept a lot, as it reflects the observation that market corrections outside of a recession are mostly short-lived and do not justify an asset rotation.`}</p>
      <p>{`Keller expanded upon this concept by switching between two asset allocations, and researching which asset allocations work best. He concludes to start with a conservative allocation similar to the `}<em parentName="p">{`Permanent Portfolio`}</em>{` and use it as the risk-off allocation. In calm times, Keller replaces cash with more stocks, significantly boosting returns. Keller describes his findings in his detailed paper.`}</p>
      <p>{`As a passive portfolio, the `}<em parentName="p">{`Permanent Portfolio`}</em>{` is highly regarded, combining decent returns with a low-risk profile. Through the addition of well thought out active management, Keller has made substantial improvements to these qualities.`}</p>
      <p>{`The `}<em parentName="p">{`Lethargic Asset Allocation`}</em>{` is included in the `}<em parentName="p">{`TuringTrader.org`}</em>{` open-source project. For those interested in diving deeper into the details, we recommend reviewing the C# source code in the repository.`}</p>
      <h2>{`Diversification`}</h2>
      <p>{`Just like the `}<em parentName="p">{`Permanent Portfolio`}</em>{`, the `}<em parentName="p">{`Lethargic Asset Allocation`}</em>{` is always well-diversified across multiple asset classes. The combination of asset classes should do well in almost all economic environments. Keller backtested the strategy from February 1949 to October 2019, showing how the strategy performed evenly across many different economic and market regimes.`}</p>
      <h2>{`Returns & Volatility`}</h2>
      <p>{`In the long term, Keller's `}<em parentName="p">{`Lethargic Asset Allocation`}</em>{` provides returns that are only slightly higher than those of a static 60/40 allocation. However, `}<em parentName="p">{`LAA`}</em>{` achieves this return with significantly lower volatility. Therefore, `}<em parentName="p">{`LAA`}</em>{`'s risk-adjusted returns, as indicated by the `}<em parentName="p">{`Ulcer Performance Index`}</em>{`, are more than twice as high.`}</p>
      <p>{`Because most of the time `}<em parentName="p">{`LAA`}</em>{` allocates only 25% to bonds, the strategy bears much less interest-rate risk than many other low-volatility portfolios. Also, the higher stock allocation helps the returns.`}</p>
      <p>{`In historical backtesting, the strategy has shown docile behavior and low drawdowns. However, investors should note that in its risk-on configuration, `}<em parentName="p">{`LAA`}</em>{` allocates 50% of the capital toward U.S. stocks. Therefore, `}<em parentName="p">{`LAA`}</em>{` may bear risks similar to a passive 50/50 allocation, when market environments shift faster than the strategy's inherent 1-month lag allows.`}</p>
      <h2>{`Account & Tax Considerations`}</h2>
      <p>{`Keller’s `}<em parentName="p">{`LAA`}</em>{` strategy triggers taxable events when switching between its risk-on and risk-off allocations. However, because switching occurs so rarely, and because 75% of the assets are held throughout, `}<em parentName="p">{`LAA`}</em>{` is very tax efficient. `}</p>
      <p>{`The `}<em parentName="p">{`Lethargic Asset Allocation`}</em>{` invests in 4 ETFs simultaneously. To do so, it requires a minimum capital of about $4,000 to function as intended.`}</p>
    </Review>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      